import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Headroom from "react-headroom"
import '../styles/header.css'
import $ from 'jquery' // important: case sensitive.
import logo from "../images/logo.png" // Tell webpack this JS file uses this image
import scrollTo from 'gatsby-plugin-smoothscroll';







const Header = () => {
  return (
    <div>
      
      
      <div id="barra-nav">
        <div className="nav-container">
          <div className="brand">
          <Link to="/" title="Homepage"><img src={logo} alt="Logo" /></Link>

          </div>
        </div>
      </div>
      <div id="menu-nav">
        <div className="menu-container">
          <div className="menu">
            <div onClick={() => scrollTo('.section-ementa')} className="item-1 futura-bold">
              Ementa
            </div>
            <div onClick={() => scrollTo('.section-contactos')} className="item-2 futura-bold">
             Contactos
              </div>
           
          </div>
        </div>
      </div>

   
  
    </div>
    
  )

}
export default Header