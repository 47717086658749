import * as React from "react"
import '../styles/style.css'
import Footer from "../components/footer"
import Header from "../components/header"
import phone from "../images/phone.png" // Tell webpack this JS file uses this image
import elemento1 from "../images/cebola.png" // Tell webpack this JS file uses this image
import elemento2 from "../images/tomate.png" // Tell webpack this JS file uses this image
import elemento3 from "../images/salada.png" // Tell webpack this JS file uses this image
import elemento4 from "../images/kebab.png" // Tell webpack this JS file uses this image
import scrollTo from 'gatsby-plugin-smoothscroll';
import submozzarella from "../images/pratos/submarino-mozzarella.png" // Tell webpack this JS file uses this image
import subcheese from "../images/pratos/submarino-cheese.png" // Tell webpack this JS file uses this image
import kebab from "../images/pratos/kebab.png" // Tell webpack this JS file uses this image
import bifegrill from "../images/pratos/bife-grill.png" // Tell webpack this JS file uses this image
import chickenburger from "../images/pratos/chicken-burger.png" // Tell webpack this JS file uses this image
import sauteed from "../images/pratos/sauteed.png" // Tell webpack this JS file uses this image
import kebabgrill from "../images/pratos/kebab-grill.png" // Tell webpack this JS file uses this image
import nada from "../images/pratos/nada.png" // Tell webpack this JS file uses this image
import krolling from "../images/pratos/k-rolling.png" // Tell webpack this JS file uses this image
import kburger from "../images/pratos/k-chicken-burger.png" // Tell webpack this JS file uses this image
import nomeat from "../images/pratos/k-rolling-nomeat.png" // Tell webpack this JS file uses this image
import saladamozzarella from "../images/pratos/salada-mozzarella.png" // Tell webpack this JS file uses this image
import saladafrango from "../images/pratos/salada-frango.png" // Tell webpack this JS file uses this image
import saladanomeat from "../images/pratos/salada-nomeat.png" // Tell webpack this JS file uses this image
import kcheese from "../images/pratos/k-cheese.png" // Tell webpack this JS file uses this image
import crunchy from "../images/pratos/crunchy.png" // Tell webpack this JS file uses this image
<meta name="viewport"></meta>
// markup
const IndexPage = () => {
  return (
    <main>
    <Header>
    </Header>
    <div className="section-1">
      <div className="fundo1">

      </div>
      <div className="fundo2">
        
        </div>
      <div className="elemento">
      <img src={elemento1} alt="Logo" />
      </div>
      <div className="elemento2">
      <img src={elemento2} alt="Logo" />
      </div>
      <div className="elemento3">
      <img src={elemento3} alt="Logo" />
      </div>
      <div className="elemento4">
      <img src={elemento4} alt="Logo" />
      </div>
      <div className="wrapper">
        <div className="col-1">
          <div className="title futura-bold">
            O melhor Kebab,<br></br>
            da cidade de Aveiro
          </div>
          <div className="desc futura-light">
            Venha visitar-nos no Centro Comercial Glicinias Plaza, em Aveiro.<br></br>
           
          </div>
        </div>
        <div className="col-2">
        <div className="elemento4-mobile mobile">
      <img src={elemento4} alt="Logo" />
      </div>
        </div>
      </div>
    </div>

    <div className="section-ementa">
      <div className="wrapper">
        <div className="ementa futura-bold">
          <span className="spanementa">
          Ementa 
          </span>
          
        </div>
        <div className="desc futura-light">
          Todos os pratos são confecionados e preparados no nosso establecimento<br></br> obedecendo naturalmente a todas as normas de higiene e segurança alimentar.
        </div>
        <div className="section">
        <div className="titulo futura-bold">
          Submarino
          
        </div>
        <div className="cards">

        
        <div className="col-1">
        <div className="card">
          <div className="foto-container">
          <img src={submozzarella} alt="Logo" />
          </div>
          <div className="text-container">
          <div className="nome futura-bold">Mozzarella
          </div> 
          <div className="ingredientes futura-light">Tomate, Alface, Cenoura, Milho, Queijo Mozzarella e Molho Ali

          </div> 
          </div>
        </div>
       
        </div>
        <div className="col-2">
        <div className="card">

        <div className="foto-container">
        <img src={subcheese} alt="Logo" />

        </div>
          <div className="text-container">
          <div className="nome futura-bold">Cheese Steak 
          </div> 
          <div className="ingredientes futura-light">(Novilho, Porco, Frango ou Peru)


</div> 
          <div className="ingredientes futura-light">Tomate, Pimento, Cebola, Queijo Gratinado e Molho Ali


          </div> 
          </div>
          </div>
          </div>
          </div>
        </div>

        <div className="section">
        <div className="titulo futura-bold">
          Kebab
          
        </div>
        <div className="cards">

        
        <div className="col-1">
        <div className="card">
          <div className="foto-container">
          <img src={kebab} alt="Logo" />
          </div>
          <div className="text-container">
          <div className="nome futura-bold">Kebab
          </div> 
          <div className="ingredientes futura-light">(Novilho, Porco, Frango ou Peru)
          </div> 
          <div className="ingredientes futura-light">Alface, Cenoura, Couve Roxa e Molho Ali


          </div> 
          </div>
        </div>
       
        </div>
       
          </div>
        </div>

        <div className="section">
        <div className="titulo futura-bold">
          Grill
          
        </div>
        <div className="cards">

        
        <div className="col-1">
        <div className="card">
          <div className="foto-container">
          <img src={kebabgrill} alt="Logo" />
          </div>
          <div className="text-container">
          <div className="nome futura-bold">Kebab Grill
          </div> 
          <div className="ingredientes futura-light">(Novilho, Porco, Frango ou Peru)

          </div> 
          <div className="ingredientes futura-light">Salada, Arroz e Batata

          </div> 
          </div>
        </div>
       
        </div>
        <div className="col-2">
        <div className="card">

        <div className="foto-container">
        <img src={bifegrill} alt="Logo" />

        </div>
          <div className="text-container">
          <div className="nome futura-bold">Bife Grill
          </div> 
          <div className="ingredientes futura-light">(Novilho)


</div> 
          <div className="ingredientes futura-light">Salada, Arroz, Batata | Molho de cogumelos ou Manteiga de Ervas Aromáticas



          </div> 
          </div>
          </div>
          </div>

          <div className="col-3">
        <div className="card">

        <div className="foto-container">
        <img src={nada} alt="Logo" />

        </div>
          <div className="text-container">
          <div className="nome futura-bold">Peito de Frango
          </div> 
       
          <div className="ingredientes futura-light">Salada, Arroz, Batata | Molho de cogumelos ou Manteiga de Ervas Aromáticas




          </div> 
          </div>
          </div>
          </div>

          <div className="col-4">
        <div className="card">

        <div className="foto-container">
        <img src={chickenburger} alt="Logo" />

        </div>
          <div className="text-container">
          <div className="nome futura-bold"> Chicken Burger
          </div> 
       
          <div className="ingredientes futura-light"> Salada, Arroz, Batata, Queijo e Ananás





          </div> 
          </div>
          </div>
          </div>
          <div className="col-5">
        <div className="card">

        <div className="foto-container">
        <img src={sauteed} alt="Logo" />

        </div>
          <div className="text-container">
          <div className="nome futura-bold"> Sauteed
          </div> 
       
          <div className="ingredientes futura-light"> 1 Carne à escolha, cebola, pimento, cogumelos, batata e salada






          </div> 
          </div>
          </div>
          </div>
          </div>
        </div>

        <div className="section">
        <div className="titulo futura-bold">
         K
          
        </div>
        <div className="cards">

        
        <div className="col-1">
        <div className="card">
          <div className="foto-container">
          <img src={krolling} alt="Logo" />
          </div>
          <div className="text-container">
          <div className="nome futura-bold">K-Rolling
          </div> 
          <div className="ingredientes futura-light">(Novilho, Porco, Frango ou Peru)

          </div> 
          <div className="ingredientes futura-light">Alface, Cenoura, Cebola, Tomate e Molho Ali


          </div> 
          </div>
        </div>
       
        </div>
        <div className="col-2">
        <div className="card">

        <div className="foto-container">
        <img src={kburger} alt="Logo" />

        </div>
          <div className="text-container">
          <div className="nome futura-bold">K-Chicken Burger
          </div> 
     
          <div className="ingredientes futura-light">Alface, Cenoura, Tomate, Queijo e Molho Ali




          </div> 
          </div>
          </div>
          </div>

          <div className="col-3">
        <div className="card">

        <div className="foto-container">
        <img src={nomeat} alt="Logo" />

        </div>
          <div className="text-container">
          <div className="nome futura-bold">Rolling No-Meat
          </div> 
       
          <div className="ingredientes futura-light">Nuggets de Soja, Alface, Cenoura, Cebola e Tomate

          </div> 
          </div>
          </div>
          </div>

       
      
          </div>
        </div>

      
        <div className="section">
        <div className="titulo futura-bold">
         Saladas
          
        </div>
        <div className="cards">

        
        <div className="col-1">
        <div className="card">
          <div className="foto-container">
          <img src={saladamozzarella} alt="Logo" />
          </div>
          <div className="text-container">
          <div className="nome futura-bold">Mozzarela
          </div> 
       
          <div className="ingredientes futura-light">Couscous, Queijo Mozzarella, Alface, Cenoura, Tomate, Pepino, Couve Roxa e Milho


          </div> 
          </div>
        </div>
       
        </div>
        <div className="col-2">
        <div className="card">

        <div className="foto-container">
        <img src={saladafrango} alt="Logo" />

        </div>
          <div className="text-container">
          <div className="nome futura-bold">Romana
          </div> 
     
          <div className="ingredientes futura-light">Peito de Frango, Alface, Cenoura, Tomate, Pepino, Couve Roxa e Milho





          </div> 
          </div>
          </div>
          </div>

          <div className="col-3">
        <div className="card">

        <div className="foto-container">
        <img src={saladanomeat} alt="Logo" />

        </div>
          <div className="text-container">
          <div className="nome futura-bold">No-Meat
          </div> 
       
          <div className="ingredientes futura-light">Nuggets de Soja, Alface, Cenoura, Tomate, Pepino, Couve Roxa e Milho


          </div> 
          </div>
          </div>
          </div>

       
      
          </div>
        </div>

        <div className="section">
        <div className="titulo futura-bold">
        K-Cheese
          
        </div>
        <div className="cards">

        
        <div className="col-1">
        <div className="card">
          <div className="foto-container">
          <img src={kcheese} alt="Logo" />
          </div>
          <div className="text-container">
          <div className="nome futura-bold">k-Cheese
          </div> 
          <div className="ingredientes futura-light">(Novilho, Porco, Frango ou Peru)


</div> 
          <div className="ingredientes futura-light">(Queijo Gratinado, Tomate e azeitonas)



          </div> 
          </div>
        </div>
       
        </div>
        <div className="col-2">
        <div className="card">

        <div className="foto-container">
        <img src={crunchy} alt="Logo" />

        </div>
          <div className="text-container">
          <div className="nome futura-bold">Crunchy K-Cheese
          </div> 
          <div className="ingredientes futura-light">(Novilho, Porco, Frango ou Peru)
          </div> 
          <div className="ingredientes futura-light">Pimento, Cogumelos, Milho, Queijo Gratinado e Cebola Frita






          </div> 
          </div>
          </div>
          </div>

   

       
      
          </div>
        </div>

      </div>
    </div>

    <div className="section-contactos">
     <div className="wrapper">
       <div className="titulo futura-bold">
         <span>Contactos</span>
       </div>
       <div className="desc futura-light">
         Entre em contacto connosco
       </div>
     </div>
     <div className="wrapper-2">
       <div className="col-1">
        <div className="contacto futura-light">
          Centro Comercial Glicínias Plaza, <br></br>
          Loja 2.18<br></br>
        R. Dom Manuel Barbuda e Vasconcelos,<br></br> 3810-498 Aveiro

        </div>
       </div>
       <div className="col-2">
       <div className="morada futura-light">
          geral@alikebab.pt <br></br>
          +351 234 421 564<br></br>
          (Custo da chamada: Rede fixa nacional)<br></br>
        

        </div>
       </div>
     </div>
    </div>
    <div className="section-footer">
      <div className="wrapper">
        <div className="col ">
         <span className="futura-light">Ali Kebab © 2021 || Handcrafted by </span>  <span className="link futura-bold"><a href="www.fortydegrees.pt" target="_blank">40º</a></span>
        </div>
        <div className="col">
          
          </div>
          <div className="col">
          
          </div>
      </div>
    </div>

    </main>
  )
}

export default IndexPage
